<template>
  <div class="home">
    <div class="Head">
      <div class="HeadingArea--pc">
        <div class="header-menu">
          <ul class="header-menu-inner">
            <li>
              <a @click="scrollToAnchorPoint('separator_line')">
                <v-icon class="first-icon" color="white" medium>
                  mdi-cellphone-text
                </v-icon>
                <span class="text"
                  >サービス<v-icon class="allow-icon" dense small>
                    mdi-chevron-down
                  </v-icon>
                </span>
              </a>
            </li>
            <li>
              <a href="/myprofile">
                <v-icon class="first-icon" color="white" medium>
                  mdi-account-box-multiple-outline
                </v-icon>
                <span class="text"
                  >My Profile<v-icon class="allow-icon" dense x-small>
                    mdi-open-in-new
                  </v-icon></span
                >
              </a>
            </li>
            <li>
              <a href="/news_release">
                <v-icon class="first-icon" color="white" medium>
                  mdi-newspaper
                </v-icon>
                <span class="text"
                  >ニュースリリース<v-icon class="allow-icon" dense small>
                    mdi-open-in-new
                  </v-icon></span
                >
              </a>
            </li>
            <li>
              <a href="/company">
                <v-icon class="first-icon" color="white" medium>
                  mdi-office-building-outline
                </v-icon>
                <span class="text"
                  >事業・IR情報<v-icon class="allow-icon" dense small>
                    mdi-open-in-new
                  </v-icon></span
                >
              </a>
            </li>
            <li>
              <a href="/support">
                <v-icon class="first-icon" color="white" medium>
                  mdi-face-agent
                </v-icon>
                <span class="text"
                  >サポート<v-icon class="allow-icon" dense small>
                    mdi-open-in-new
                  </v-icon></span
                >
              </a>
            </li>
          </ul>
        </div>
        <div class="header-tools">
          <ul class="header-tools-inner first-block">
            <li class="inline-block">
              <a href="/NotFound">
                <v-icon class="first-icon" color="white" small>
                  mdi-message-question-outline
                </v-icon>
                <span class="align-top">よくあるご質問(FAQ)</span>
              </a>
            </li>
            <li class="inline-block">
              <a href="/NotFound">
                <v-icon class="first-icon" color="white" small>
                  mdi-human-greeting-proximity
                </v-icon>
                <span class="align-top">お問い合わせ</span>
              </a>
            </li>
          </ul>
          <ul class="header-tools-inner second-block">
            <li class="inline-block register-button">
              <a>
                <span class="align-bottom">新規登録</span>
              </a>
            </li>
            <li class="inline-block login-button">
              <a>
                <v-icon color="white" small>
                  mdi-login
                </v-icon>
                <span class="align-bottom">ログイン</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="HeadingArea--mobile">
        <div class="header-menu">
          <ul>
            <li>
              <a class="rb-h-smart-menu__btn">
                <v-icon color="white" medium>
                  mdi-account-plus-outline
                </v-icon>
                新規登録
              </a>
            </li>
            <li>
              <a href="/support" class="rb-h-smart-menu__btn">
                <v-icon color="white" medium>
                  mdi-message-question-outline
                </v-icon>
                ヘルプ
              </a>
            </li>
            <li>
              <v-menu left bottom :offset-y="true">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon color="white" medium>
                      mdi-table-of-contents
                    </v-icon>
                    <span>メニュー</span>
                  </v-btn>
                </template>

                <v-list>
                  <div>
                    <v-list-item @click="scrollToAnchorPoint('separator_line')">
                      <v-list-item-title>
                        サービス
                        <v-icon right small>
                          mdi-cellphone-text
                        </v-icon>
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item href="/myprofile">
                      <v-list-item-title>
                        My Profile
                        <v-icon right small>
                          mdi-account-box-multiple-outline
                        </v-icon>
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item href="/news_release">
                      <v-list-item-title>
                        ニュースリリース
                        <v-icon right small>
                          mdi-newspaper
                        </v-icon>
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item href="/company">
                      <v-list-item-title>
                        事業・IR情報
                        <v-icon right small>
                          mdi-office-building-outline
                        </v-icon>
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item href="/support">
                      <v-list-item-title>
                        サポート
                        <v-icon right small>
                          mdi-face-agent
                        </v-icon>
                      </v-list-item-title>
                    </v-list-item>
                  </div>
                </v-list>
              </v-menu>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="main-content">
      <div class="titleArea">
        <div class="textArea">
          <h2 class="titles">
            同性代の人と音声と趣味で<br />繋がる<span
              >コミュニティサービス</span
            >
          </h2>
          <div class="bannerArea">
            <p class="lead">
              290万人以上のユーザーが利用中！
            </p>
          </div>
          <ul class="bannerList">
            <li class="ios">
              <a
                href="https://apps.apple.com/JP/app/id1490387554"
                target="_blank"
                rel="noopener noreferrer"
                data-rapid_p="1"
                ><img
                  src="https://storage.googleapis.com/bexix-c3ac1.appspot.com/bnr_ios.png"
                  alt=""
                  controlsList="nodownload"
                /><span>iOS12.0〜対応</span></a
              >
            </li>
            <li class="android">
              <a
                href="http://play.google.com/store/apps/details?id=jp.nanameue.yay"
                target="_blank"
                rel="noopener noreferrer"
                data-rapid_p="2"
                ><img
                  src="https://storage.googleapis.com/bexix-c3ac1.appspot.com/bnr_android.png"
                  alt=""
                  controlsList="nodownload"
                /><span>Android5.0〜対応</span></a
              >
            </li>
          </ul>
          <p class="warning-note">
            ※当方は上記アプリの開発、提供元ではありません。<br />
            詳しくは「Nanameue inc.」様のサイトをご覧ください。
          </p>
        </div>
        <div class="imgArea" oncontextmenu="return false;">
          <p>
            <img
              src="https://storage.googleapis.com/bexix-c3ac1.appspot.com/smartmockups_kmkwjrkp.png"
              alt=""
              controlsList="nodownload"
            />
          </p>
        </div>
      </div>
      <div class="separator_line" ref="separator_line">
        <hr class="box" />
      </div>
      <div class="company_overview">
        <div class="top_title">
          <h2 class="titles">
            <span class="mainline"> 既存サービスをさらに使いやすく</span>
            <span class="subline"> 開発から提供までを個人で行っています。</span>
          </h2>
        </div>
        <div class="list_description">
          <div class="list_Yaybexix description_wrapper">
            <div class="videoArea" oncontextmenu="return false;">
              <video
                src="https://storage.googleapis.com/bexix-c3ac1.appspot.com/smartmockups_video1.mp4"
                alt=""
                autoplay
                loop
                muted
                playsinline
                controlsList="nodownload"
              />
            </div>
            <div class="textArea">
              <h2 class="titles">YAY Bexix</h2>
              <p class="description">
                <span class="mainline">半自動化ですべてのユーザーを</span>
                <span class="subline">上位クラスへ。</span>
              </p>
              <p class="more_link">
                <a href="#" class="link-wrapper"
                  ><span class="icon-copy">さらに詳しく</span
                  ><span class="icon-after"></span
                ></a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    scrollToAnchorPoint(refName) {
      const el = this.$refs[refName];
      el.scrollIntoView({ behavior: "smooth" });
    },
  },
};
</script>

<style>
.Head {
  background-color: #272727;
  height: auto;
  margin-right: calc(50% - 50vw);
  margin-left: calc(50% - 50vw);
}

.HeadingArea--pc {
  background-color: #272727;
  height: auto;
  margin: 0 auto;
  padding-bottom: 4px;
  display: table;
}

@media (max-width: 849px) {
  .HeadingArea--pc {
    display: none;
  }
}

.HeadingArea--pc .header-menu {
  display: table-cell;
}

.HeadingArea--pc .header-menu-inner {
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}

.HeadingArea--pc .header-menu-inner > li:first-child:before {
  content: "";
  background: #333333;
  width: 1px;
  top: 9px;
  bottom: 9px;
  position: absolute;
  left: 0;
}

.HeadingArea--pc .header-menu-inner > li:after {
  content: "";
  background: #333333;
  width: 1px;
  top: 9px;
  bottom: 9px;
  position: absolute;
  right: 0;
}

.HeadingArea--pc .header-menu-inner li {
  display: flex;
  align-items: center;
  position: relative;
}

.HeadingArea--pc .header-menu-inner li a {
  padding: 14px 16px;
  text-decoration-line: none;
  text-align: center;
  height: 84px;
}

@media (max-width: 1100px) {
  .HeadingArea--pc .header-menu-inner li a {
    padding: 14px 10px;
  }
}

.HeadingArea--pc .header-menu-inner li a:hover {
  border-bottom: 4px solid #00a0e9;
}

.HeadingArea--pc .header-menu-inner li .first-icon {
  height: 35px;
}

.HeadingArea--pc .header-menu-inner li a .text {
  display: block;
  font-weight: 550;
  font-size: 14px;
  color: #fff;
  margin-left: 16px;
}

.HeadingArea--pc .header-menu-inner li a .allow-icon {
  color: #616161;
  margin-left: 5px;
}

.HeadingArea--pc .header-tools {
  display: table-cell;
  vertical-align: middle;
}

.HeadingArea--pc .header-tools-inner {
  display: block;
  font-size: 14px;
}

.HeadingArea--pc .first-block {
  margin-bottom: 13px;
}

@media (max-width: 1000px) {
  .HeadingArea--pc .first-block {
    display: none;
  }
}

.HeadingArea--pc .second-block {
  text-align: center;
}

.HeadingArea--pc .header-tools-inner li {
  list-style: none;
  display: block;
}

@media (min-width: 1000px) {
  .HeadingArea--pc .header-tools-inner li {
    display: inline-block;
  }
}

@media (min-width: 1000px) {
  .HeadingArea--pc .header-tools-inner li:nth-child(n + 2) {
    margin-left: 25px;
  }
}

@media (max-width: 1000px) {
  .HeadingArea--pc .header-tools-inner li:nth-child(n + 2) {
    margin-top: 15px;
  }
}

.HeadingArea--pc .header-tools-inner li a {
  text-decoration-line: none;
}

.HeadingArea--pc .header-tools-inner li a:hover {
  opacity: 0.5;
}

.HeadingArea--pc .header-tools-inner li a .v-icon {
  margin-right: 5px;
}

.HeadingArea--pc .header-tools-inner li a .align-top {
  color: #fff;
}

.HeadingArea--pc .header-tools-inner .login-button a {
  padding: 0.2em 0.8em;
  color: #fff;
  border: solid 1px #fff;
  border-radius: 10px;
}

.HeadingArea--pc .header-tools-inner .register-button a {
  background-color: #fff;
  padding: 0.2em 1.4em;
  color: #272727;
  font-weight: 600;
  border-radius: 10px;
  display: block;
  width: 100%;
}

.HeadingArea--mobile {
  background-color: #272727;
  height: auto;
  margin: 0 auto;
  padding: 7px;
}

@media (min-width: 850px) {
  .HeadingArea--mobile {
    display: none;
  }
}

.HeadingArea--mobile .header-menu ul {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.HeadingArea--mobile .header-menu li {
  list-style: none;
  display: inline-block;
  height: 48px;
}

.HeadingArea--mobile .header-menu li:nth-child(n + 2) {
  margin-left: 15px;
}

.HeadingArea--mobile .header-menu li a,
.HeadingArea--mobile .header-menu li .v-btn {
  text-decoration-line: none;
  font-size: 13px;
  color: #fff;
  text-align: center;
  width: auto;
}

.HeadingArea--mobile .header-menu li i {
  display: block;
  margin: 0 auto;
}

.HeadingArea--mobile .header-menu .v-btn {
  height: 100%;
}

.HeadingArea--mobile .header-menu .v-btn__content {
  display: block !important;
  text-align: center;
}

.HeadingArea--mobile .header-menu .v-btn:before {
  display: none;
}

.HeadingArea--mobile .header-menu .v-ripple__container {
  display: none;
}

.main-content {
  padding: 2em;
  margin: 0 auto;
  max-width: 950px;
}

.titleArea {
  position: relative;
  align-items: center;
  justify-content: center;
}

@media (min-width: 920px) {
  .titleArea .textArea {
    margin-right: 70px;
  }
}

.titleArea .titles {
  font-weight: 400;
  font-size: 2em;
  line-height: 1.33;
  padding: 16px 0 0;
  margin-top: -20px;
}

@media (max-width: 920px) {
  .titleArea .titles {
    text-align: center;
    font-size: 4.5vw;
  }
}

.titleArea .titles span {
  font-weight: 700;
  font-size: 1.15em;
}

@media (min-width: 920px) {
  .titleArea {
    display: flex;
  }
}

.titleArea .bannerArea {
  display: table;
  margin: 0 auto;
  margin-top: 25px;
}

.titleArea .bannerArea .lead {
  width: 420px;
  text-align: center;
  font-size: 1.06rem;
  font-weight: 700;
  background: #feed33;
  border-radius: 3px;
  padding: 4px 20px;
  line-height: 1;
  letter-spacing: 0.1em;
}

@media (max-width: 650px) {
  .titleArea .bannerArea .lead {
    font-size: 3vw;
    width: 390px;
  }
}

@media (max-width: 450px) {
  .titleArea .bannerArea .lead {
    width: 100%;
  }
}

.titleArea .bannerList {
  margin-top: 20px;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 18px;
}

@media (max-width: 600px) {
  .titleArea .bannerList {
    zoom: 80%;
  }
}

.titleArea .bannerList a {
  text-decoration: none;
}

.titleArea li {
  list-style: none;
}

.titleArea .bannerList .ios img {
  width: 143px;
}

.titleArea .bannerList .android {
  margin-left: 12px;
}

.titleArea .bannerList .android img {
  width: 139px;
}

.titleArea .bannerList span {
  display: block;
  font-size: 0.75rem;
  color: #666;
  margin-top: 1px;
}

.titleArea .imgArea {
  text-align: center;
}

.titleArea .imgArea img {
  width: 300px;
  margin-top: 23px;
}

@media (max-width: 600px) {
  .titleArea .imgArea img {
    width: 200px;
  }
}

.titleArea .warning-note {
  text-align: center;
  margin-top: 10px;
  font-size: 0.7em;
  color: #666;
}

.separator_line {
  margin: 6em auto;
}

.separator_line hr.box {
  height: 1px;
  background-image: linear-gradient(
    90deg,
    hsla(0, 0%, 100%, 0),
    hsla(0, 0%, 100%, 0.5) 50%,
    hsla(0, 0%, 100%, 0) 100%
  );
}

.company_overview {
  margin-bottom: 50px;
}

.company_overview .top_title {
  margin-bottom: 80px;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
}

@media (max-width: 770px) {
  .company_overview .top_title {
    font-size: 2.6vw;
  }
}

@media (min-width: 770px) and (max-width: 920px) {
  .company_overview .top_title {
    font-size: 2.15vw;
  }
}

.company_overview .top_title .titles {
  padding-left: 25px;
  display: inline-block;
  text-align: left;
}

.company_overview .top_title span {
  display: block;
}

.company_overview .top_title .mainline {
  color: #6e6e73;
}

@media (min-width: 920px) {
  .company_overview .list_description .description_wrapper {
    display: flex;
  }
}

.company_overview .list_description .videoArea {
  text-align: center;
}

.company_overview .list_description .videoArea video {
  width: 380px;
}

@media (max-width: 750px) {
  .company_overview .list_description .videoArea video {
    width: 280px;
  }
}

.company_overview .list_description .textArea {
  margin: 30px auto;
  width: 460px;
  display: block;
}

@media (max-width: 500px) {
  .company_overview .list_description .textArea .description {
    font-size: 4vw !important;
  }
}

.company_overview .list_description .textArea .titles {
  font-size: 28px;
  font-weight: 600;
}

.company_overview .list_description .textArea .description {
  font-size: 20px;
  font-weight: 600;
}

.company_overview .list_description .textArea .description span {
  display: block;
}

.company_overview .list_description .list_Yaybexix .textArea {
  width: 280px;
}

.company_overview .list_description .textArea .more_link {
  color: #2997ff;
  font-size: 15px;
  font-weight: 500;
}

.company_overview .list_description .textArea .more_link a {
  text-decoration: none;
}

.company_overview .list_description .textArea .more_link a:hover {
  border-bottom: 1px solid #2997ff;
}

.company_overview .list_description .textArea .icon-after {
  position: relative;
  padding-left: 15px;
  margin-left: 5px;
}

.company_overview .list_description .textArea .icon-after::before {
  content: "";
  position: absolute;
  top: 7px;
  left: 0;
  transform: rotate(225deg);
  border-bottom: 1.5px solid #2997ff;
  border-left: 1.5px solid #2997ff;
  width: 7px;
  height: 7px;
}
</style>
