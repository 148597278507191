import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

import Store from "@/store/index.js";
import VueAnalytics from "vue-analytics";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: { title: "トップページ" },
  },
  {
    path: "/terms",
    name: "Terms",
    component: () => import("../views/Terms.vue"),
    meta: { title: "利用規約" },
  },
  {
    path: "/privacy",
    name: "Privacy",
    component: () => import("../views/Privacy.vue"),
    meta: { title: "プライバシーポリシー" },
  },
  {
    path: "/company",
    name: "Company",
    component: () => import("../views/Company.vue"),
    meta: { title: "事業・IR情報" },
  },
  {
    path: "/news_release",
    name: "Release",
    component: () => import("../views/Release.vue"),
    meta: { title: "ニュースリリース" },
  },
  {
    path: "/support",
    name: "Support",
    component: () => import("../views/Support.vue"),
    meta: { title: "サポート" },
  },
  {
    path: "/about",
    name: "About",
    component: () => import("../views/About.vue"),
    meta: { title: "更新履歴" },
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue"),
    meta: { title: "ログイン" },
  },
  {
    path: "/profile",
    name: "Profile",
    component: () => import("../views/Profile.vue"),
    meta: { title: "プロフィール" },
  },
  {
    path: "/myprofile",
    name: "MyProfile",
    component: () => import("../views/MyProfile.vue"),
    meta: { title: "マイプロフィール", requiresAuth: true },
  },
  {
    path: "/block/receive",
    name: "Block_rec",
    component: () => import("../views/Block_rec.vue"),
    meta: { title: "ブロックリスト", requiresAuth: true },
  },
  {
    path: "/settng/pin_change",
    name: "Setting_pin",
    component: () => import("../views/Setting_pin.vue"),
    meta: { title: "投稿ピン変更", requiresAuth: true },
  },
  {
    path: "/notfound",
    alias: "*",
    name: "NotFound",
    component: () => import("../views/NotFound.vue"),
    meta: { title: "お探しのページは見つかりませんでした" },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes,
});

Vue.use(VueAnalytics, {
  id: "UA-193139110-1",
  router,
  autoTracking: {
    screenview: true,
  },
});

// 画面遷移前に実行される処理
router.beforeEach((to, from, next) => {
  if (
    to.matched.some((record) => record.meta.requiresAuth) &&
    !Store.state.userToken
  ) {
    next({
      path: "/login",
      query: {
        redirect: to.fullPath,
      },
    });
  } else {
    next();
  }
});

export default router;
