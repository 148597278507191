import Vue from "vue";
import Vuex from "vuex";

import createPersistedState from "vuex-persistedstate";
import * as Cookies from "js-cookie";

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      key: "BexixWebApp",
      paths: ["userId", "userToken"],
      storage: {
        getItem: (key) => Cookies.get(key),
        setItem: (key, value) =>
          Cookies.set(key, value, { expires: 30, secure: true }),
        removeItem: (key) => Cookies.remove(key),
      },
    }),
  ],
  state: {
    userId: "",
    userToken: "",
  },
  getters: {
    userId: (state) => state.userId,
    userToken: (state) => state.userToken,
  },
  mutations: {
    updateUser(state, user) {
      state.userId = user.userId;
      state.userToken = user.userToken;
    },
  },
  actions: {
    auth(context, user) {
      context.commit("updateUser", user);
    },
  },
  modules: {},
});
