"use strict";

module.exports = {
  mainPage: {
    profile: function(ga) {
      ga.event("メインページ", "プロフィール", "", 1);
    },
    block_rec: function(ga) {
      ga.event("メインページ", "ブロックリスト", "", 1);
    },
    logout: function(ga) {
      ga.event("メインページ", "ログアウト", "", 1);
    },
    login: function(ga) {
      ga.event("メインページ", "ログイン", "", 1);
    },
    about: function(ga) {
      ga.event("メインページ", "更新履歴", "", 1);
    },
    appinfo: function(ga) {
      ga.event("メインページ", "アプリ情報", "", 1);
    },
    terms: function(ga) {
      ga.event("メインページ", "利用規約", "", 1);
    },
    privacy: function(ga) {
      ga.event("メインページ", "プライバシーポリシー", "", 1);
    },
  },
  loginPage: {
    login: function(ga) {
      ga.event("ログインページ", "ログイン", "", 1);
    },
  },
};
