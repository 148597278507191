import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "@mdi/font/css/materialdesignicons.css";

// Axios
import axios from "axios";
import VueAxios from "vue-axios";
Vue.$http = axios;
Vue.use(VueAxios, axios);

// Analytics
import VueAnalytics from "vue-analytics";
Vue.use(VueAnalytics, {
  id: "UA-193139110-1",
});

// vue-lazyload
import VueLazyload from "vue-lazyload";
Vue.use(VueLazyload, {
  observer: true,
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
